export const Hexes = [
  {
    q: 0,
    r: -2,
    s: 2,
    fill: "wheat",
    number: 2,
    id: 0,
    neighbors: [1, 3, 4],
  },
  {
    q: 1,
    r: -2,
    s: 1,
    fill: "wheat",
    number: 3,
    id: 1,
    neighbors: [0, 2, 4, 5],
  },
  {
    q: 2,
    r: -2,
    s: 0,
    fill: "wheat",
    number: 3,
    id: 2,
    neighbors: [1, 5, 6],
  },
  {
    q: -1,
    r: -1,
    s: 2,
    fill: "wheat",
    number: 4,
    id: 3,
    neighbors: [0, 4, 7, 8],
  },
  {
    q: 0,
    r: -1,
    s: 1,
    fill: "forest",
    number: 4,
    id: 4,
    neighbors: [0, 1, 3, 5, 8, 9],
  },
  {
    q: 1,
    r: -1,
    s: 0,
    fill: "forest",
    number: 5,
    id: 5,
    neighbors: [1, 2, 4, 6, 9, 10],
  },
  {
    q: 2,
    r: -1,
    s: -1,
    fill: "forest",
    number: 5,
    id: 6,
    neighbors: [2, 5, 10, 11],
  },
  {
    q: -2,
    r: 0,
    s: 2,
    fill: "forest",
    number: 6,
    id: 7,
    neighbors: [3, 8, 12],
  },
  {
    q: -1,
    r: 0,
    s: 1,
    fill: "ore",
    number: 6,
    id: 8,
    neighbors: [3, 4, 7, 9, 12, 13],
  },
  {
    q: 0,
    r: 0,
    s: 0,
    fill: "ore",
    number: 8,
    id: 9,
    neighbors: [4, 5, 8, 10, 13, 14],
  },
  {
    q: 1,
    r: 0,
    s: -1,
    fill: "ore",
    number: 8,
    id: 10,
    neighbors: [5, 6, 9, 11, 14, 15],
  },
  {
    q: 2,
    r: 0,
    s: -2,
    fill: "sheep",
    number: 9,
    id: 11,
    neighbors: [6, 10, 15],
  },
  {
    q: -2,
    r: 1,
    s: 1,
    fill: "sheep",
    number: 9,
    id: 12,
    neighbors: [7, 8, 13, 16],
  },
  {
    q: -1,
    r: 1,
    s: 0,
    fill: "sheep",
    number: 10,
    id: 13,
    neighbors: [8, 9, 12, 14, 16, 17],
  },
  {
    q: 0,
    r: 1,
    s: -1,
    fill: "sheep",
    number: 10,
    id: 14,
    neighbors: [9, 10, 13, 15, 17, 18],
  },
  {
    q: 1,
    r: 1,
    s: -2,
    fill: "brick",
    number: 11,
    id: 15,
    neighbors: [10, 11, 14, 18],
  },
  {
    q: -2,
    r: 2,
    s: 0,
    fill: "brick",
    number: 11,
    id: 16,
    neighbors: [12, 13, 17],
  },
  {
    q: -1,
    r: 2,
    s: -1,
    fill: "brick",
    number: 12,
    id: 17,
    neighbors: [13, 14, 16, 18],
  },
  {
    q: 0,
    r: 2,
    s: -2,
    fill: "desert",
    number: null,
    id: 18,
    neighbors: [14, 15, 17],
  },
];

export const Bridges = [
  {
    q: 0,
    r: -3,
    s: 0,
    fill: "bridges-left",
    id: 0,
  },
  {
    q: -2,
    r: -1,
    s: 3,
    fill: "bridges-bottom-left",
    id: 0,
  },
  {
    q: -3,
    r: 1,
    s: 1,
    fill: "bridges-bottom-left",
    id: 0,
  },
  {
    q: -3,
    r: 3,
    s: 1,
    fill: "bridges-bottom-right",
    id: 0,
  },
  {
    q: -1,
    r: 3,
    s: 1,
    fill: "bridges-right",
    id: 0,
  },
  {
    q: 1,
    r: 2,
    s: 3,
    fill: "bridges-right",
    id: 0,
  },
  {
    q: 3,
    r: 0,
    s: 3,
    fill: "bridges-top-right",
    id: 0,
  },
  {
    q: 3,
    r: -2,
    s: 3,
    fill: "bridges-top-left",
    id: 0,
  },
  {
    q: 2,
    r: -3,
    s: 3,
    fill: "bridges-top-left",
    id: 0,
  },
];

export const Ports = [
  {
    q: 0,
    r: -3,
    s: 0,
    fill: "any",
    id: 0,
  },
  {
    q: -2,
    r: -1,
    s: 3,
    fill: "any",
    id: 0,
  },
  {
    q: -3,
    r: 1,
    s: 1,
    fill: "any",
    id: 0,
  },
  {
    q: -3,
    r: 3,
    s: 1,
    fill: "any",
    id: 0,
  },
  {
    q: -1,
    r: 3,
    s: 1,
    fill: "wood-port",
    id: 0,
  },
  {
    q: 1,
    r: 2,
    s: 3,
    fill: "brick-port",
    id: 0,
  },
  {
    q: 3,
    r: 0,
    s: 3,
    fill: "sheep-port",
    id: 0,
  },
  {
    q: 3,
    r: -2,
    s: 3,
    fill: "wheat-port",
    id: 0,
  },
  {
    q: 2,
    r: -3,
    s: 3,
    fill: "ore-port",
    id: 0,
  },
];

