export const Hexes = [
  {
    q: 0,
    r: 0,
    s: 0,
    fill: "desert",
    number: null,
    id: 0,
    neighbors: [1, 2, 3, 4, 5, 6],
    hidden: false,
    locked: true
  },
  {
    q: 1,
    r: 0,
    s: 0,
    fill: "ocean",
    number: null,
    id: 1,
    neighbors: [0, 2, 6, 7, 8, 9],
    hidden: false,
    locked: true
  },
  {
    q: 0,
    r: 1,
    s: 0,
    fill: "ocean",
    number: null,
    id: 2,
    neighbors: [0, 1, 3, 9, 10, 11],
    hidden: false,
    locked: true
  },
  {
    q: -1,
    r: 1,
    s: 0,
    fill: "ocean",
    number: null,
    id: 3,
    neighbors: [0, 2, 4, 11, 12, 13],
    hidden: false,
    locked: true
  },
  {
    q: -1,
    r: 0,
    s: 0,
    fill: "ocean",
    number: null,
    id: 4,
    neighbors: [0, 3, 5, 13, 14, 15],
    hidden: false,
    locked: true
  },
  {
    q: 0,
    r: -1,
    s: 0,
    fill: "ocean",
    number: null,
    id: 5,
    neighbors: [0, 4, 6, 15, 16, 17],
    hidden: false,
    locked: true
  },
  {
    q: 1,
    r: -1,
    s: 0,
    fill: "ocean",
    number: null,
    id: 6,
    neighbors: [0, 1, 5, 7, 17, 18],
    hidden: false,
    locked: true
  },
  {
    q: 2,
    r: -1,
    s: 0,
    fill: "forest",
    number: 2,
    id: 7,
    neighbors: [1, 6, 8, 18, 19, 20],
    hidden: false,
    locked: true
  },
  {
    q: 2,
    r: 0,
    s: 0,
    fill: "forest",
    number: 3,
    id: 8,
    neighbors: [1, 7, 9, 20, 21, 22],
    hidden: false,
    locked: true
  },
  {
    q: 1,
    r: 1,
    s: 0,
    fill: "forest",
    number: 4,
    id: 9,
    neighbors: [1, 2, 8, 10, 22, 23],
    hidden: false,
    locked: true
  },
  {
    q: 0,
    r: 2,
    s: 0,
    fill: "forest",
    number: 4,
    id: 10,
    neighbors: [2, 9, 11, 23, 24, 25],
    hidden: false,
    locked: true
  },
  {
    q: -1,
    r: 2,
    s: 0,
    fill: "forest",
    number: 4,
    id: 11,
    neighbors: [2, 3, 10, 12, 25, 26],
    hidden: false,
    locked: true
  },
  {
    q: -2,
    r: 2,
    s: 0,
    fill: "forest",
    number: 5,
    id: 12,
    neighbors: [3, 11, 13, 26],
    hidden: false,
    locked: true
  },
  {
    q: -2,
    r: 1,
    s: 0,
    fill: "forest",
    number: 5,
    id: 13,
    neighbors: [3, 4, 12, 14],
    hidden: false,
    locked: true
  },
  {
    q: -2,
    r: 0,
    s: 0,
    fill: "forest",
    number: 5,
    id: 14,
    neighbors: [4, 13, 15, 27],
    hidden: false,
    locked: true
  },
  {
    q: -1,
    r: -1,
    s: 0,
    fill: "forest",
    number: 5,
    id: 15,
    neighbors: [4, 5, 14, 16, 27, 28],
    hidden: false,
    locked: true
  },
  {
    q: 0,
    r: -2,
    s: 0,
    fill: "forest",
    number: 6,
    id: 16,
    neighbors: [5, 15, 17, 28, 29, 30],
    hidden: false,
    locked: true
  },
  {
    q: 1,
    r: -2,
    s: 0,
    fill: "forest",
    number: 6,
    id: 17,
    neighbors: [5, 6, 16, 18, 30, 31],
    hidden: false,
    locked: true
  },
  {
    q: 2,
    r: -2,
    s: 0,
    fill: "forest",
    number: 6,
    id: 18,
    neighbors: [6, 7, 17, 19, 31, 32],
    hidden: false,
    locked: true
  },
  {
    q: 3,
    r: -2,
    s: 0,
    fill: "forest",
    number: 8,
    id: 19,
    neighbors: [7, 18, 20, 32, 33, 34],
    hidden: false,
    locked: true
  },
  {
    q: 3,
    r: -1,
    s: 0,
    fill: "forest",
    number: 8,
    id: 20,
    neighbors: [7, 8, 19, 21, 34, 35],
    hidden: false,
    locked: true
  },
  {
    q: 3,
    r: 0,
    s: 0,
    fill: "brick",
    number: 3,
    id: 21,
    neighbors: [8, 20, 22, 35, 36, 37],
    locked: false,
    hidden: true
  },
  {
    q: 2,
    r: 1,
    s: 0,
    fill: "forest",
    number: 8,
    id: 22,
    neighbors: [8, 9, 21, 23, 37, 38],
    hidden: false,
    locked: true
  },
  {
    q: 1,
    r: 2,
    s: 0,
    fill: "forest",
    number: 9,
    id: 23,
    neighbors: [9, 10, 22, 24, 38, 39],
    hidden: false,
    locked: true
  },
  {
    q: 0,
    r: 3,
    s: 0,
    fill: "brick",
    number: 4,
    id: 24,
    neighbors: [10, 23, 25, 39, 44, 45],
    locked: false,
    hidden: true
  },
  {
    q: -1,
    r: 3,
    s: 0,
    fill: "forest",
    number: 9,
    id: 25,
    neighbors: [10, 11, 24, 26, 45, 46],
    hidden: false,
    locked: true
  },
  {
    q: -2,
    r: 3,
    s: 0,
    fill: "forest",
    number: 9,
    id: 26,
    neighbors: [11, 12, 25, 46, 47, 48],
    hidden: false,
    locked: true
  },
  {
    q: -2,
    r: -1,
    s: 0,
    fill: "forest",
    number: 9,
    id: 27,
    neighbors: [14, 15, 28, 51, 52, 53],
    hidden: false,
    locked: true
  },
  {
    q: -1,
    r: -2,
    s: 0,
    fill: "forest",
    number: 10,
    id: 28,
    neighbors: [15, 16, 27, 29, 53, 54],
    hidden: false,
    locked: true
  },
  {
    q: 0,
    r: -3,
    s: 0,
    fill: "brick",
    number: 4,
    id: 29,
    neighbors: [16, 28, 30, 40, 54, 55],
    locked: false,
    hidden: true
  },
  {
    q: 1,
    r: -3,
    s: 0,
    fill: "forest",
    number: 10,
    id: 30,
    neighbors: [16, 17, 29, 31, 40, 41],
    hidden: false,
    locked: true
  },
  {
    q: 2,
    r: -3,
    s: 0,
    fill: "forest",
    number: 10,
    id: 31,
    neighbors: [17, 18, 30, 32, 41, 42],
    hidden: false,
    locked: true
  },
  {
    q: 3,
    r: -3,
    s: 0,
    fill: "brick",
    number: 4,
    id: 32,
    neighbors: [18, 19, 31, 33, 42, 43],
    locked: false,
    hidden: true
  },
  {
    q: 4,
    r: -3,
    s: 0,
    fill: "brick",
    number: 4,
    id: 33,
    neighbors: [19, 32, 34, 43],
    locked: false,
    hidden: true
  },
  {
    q: 4,
    r: -2,
    s: 0,
    fill: "brick",
    number: 5,
    id: 34,
    neighbors: [19, 20, 33, 35],
    locked: false,
    hidden: true
  },
  {
    q: 4,
    r: -1,
    s: 0,
    fill: "brick",
    number: 5,
    id: 35,
    neighbors: [20, 21, 34, 36],
    locked: false,
    hidden: true
  },
  {
    q: 4,
    r: 0,
    s: 0,
    fill: "brick",
    number: 5,
    id: 36,
    neighbors: [21, 35, 37],
    locked: false,
    hidden: true
  },
  {
    q: 3,
    r: 1,
    s: 0,
    fill: "brick",
    number: 5,
    id: 37,
    neighbors: [21, 22, 36, 38],
    locked: false,
    hidden: true
  },
  {
    q: 2,
    r: 2,
    s: 0,
    fill: "sheep",
    number: 5,
    id: 38,
    neighbors: [22, 23, 37, 39],
    locked: false,
    hidden: true
  },
  {
    q: 1,
    r: 3,
    s: 0,
    fill: "sheep",
    number: 6,
    id: 39,
    neighbors: [23, 24, 38],
    locked: false,
    hidden: true
  },
  {
    q: 1,
    r: -4,
    s: 0,
    fill: "sheep",
    number: 6,
    id: 40,
    neighbors: [29, 30, 41, 55],
    locked: false,
    hidden: true
  },
  {
    q: 2,
    r: -4,
    s: 0,
    fill: "sheep",
    number: 6,
    id: 41,
    neighbors: [30, 31, 40, 42],
    locked: false,
    hidden: true
  },
  {
    q: 3,
    r: -4,
    s: 0,
    fill: "sheep",
    number: 6,
    id: 42,
    neighbors: [31, 32, 41, 43],
    locked: false,
    hidden: true
  },
  {
    q: 4,
    r: -4,
    s: 0,
    fill: "sheep",
    number: 6,
    id: 43,
    neighbors: [32, 33, 42],
    locked: false,
    hidden: true
  },
  {
    q: 0,
    r: 4,
    s: 0,
    fill: "sheep",
    number: 8,
    id: 44,
    neighbors: [24, 39, 45],
    locked: false,
    hidden: true
  },
  {
    q: -1,
    r: 4,
    s: 0,
    fill: "sheep",
    number: 8,
    id: 45,
    neighbors: [24, 25, 44, 46],
    locked: false,
    hidden: true
  },
  {
    q: -2,
    r: 4,
    s: 0,
    fill: "wheat",
    number: 8,
    id: 46,
    neighbors: [25, 26, 45, 47],
    locked: false,
    hidden: true
  },
  {
    q: -3,
    r: 4,
    s: 0,
    fill: "wheat",
    number: 8,
    id: 47,
    neighbors: [26, 46, 48, 56],
    locked: false,
    hidden: true
  },
  {
    q: -3,
    r: 3,
    s: 0,
    fill: "wheat",
    number: 8,
    id: 48,
    neighbors: [12, 26, 47, 49, 56, 57],
    locked: false,
    hidden: true
  },
  {
    q: -3,
    r: 2,
    s: 0,
    fill: "forest",
    number: 11,
    id: 49,
    neighbors: [12, 13, 48, 50, 57, 58],
    locked: true,
    hidden: false
  },
  {
    q: -3,
    r: 1,
    s: 0,
    fill: "forest",
    number: 12,
    id: 50,
    neighbors: [13, 14, 49, 51, 58, 59],
    locked: true,
    hidden: false
  },
  {
    q: -3,
    r: 0,
    s: 0,
    fill: "wheat",
    number: 9,
    id: 51,
    neighbors: [14, 27, 50, 52, 59, 60],
    locked: false,
    hidden: true
  },
  {
    q: -3,
    r: -1,
    s: 0,
    fill: "wheat",
    number: 9,
    id: 52,
    neighbors: [27, 51, 53, 60],
    locked: false,
    hidden: true
  },
  {
    q: -2,
    r: -2,
    s: 0,
    fill: "wheat",
    number: 9,
    id: 53,
    neighbors: [27, 28, 52, 54],
    locked: false,
    hidden: true
  },
  {
    q: -1,
    r: -3,
    s: 0,
    fill: "wheat",
    number: 9,
    id: 54,
    neighbors: [28, 29, 53, 55],
    locked: false,
    hidden: true
  },
  {
    q: 0,
    r: -4,
    s: 0,
    fill: "ore",
    number: 9,
    id: 55,
    neighbors: [29, 40, 54],
    locked: false,
    hidden: true
  },
  {
    q: -4,
    r: 4,
    s: 0,
    fill: "ore",
    number: 10,
    id: 56,
    neighbors: [47, 48, 57],
    locked: false,
    hidden: true
  },
  {
    q: -4,
    r: 3,
    s: 0,
    fill: "ore",
    number: 10,
    id: 57,
    neighbors: [48, 49, 56, 58],
    locked: false,
    hidden: true
  },
  {
    q: -4,
    r: 2,
    s: 0,
    fill: "ore",
    number: 10,
    id: 58,
    neighbors: [49, 50, 57, 59],
    locked: false,
    hidden: true
  },
  {
    q: -4,
    r: 1,
    s: 0,
    fill: "ore",
    number: 10,
    id: 59,
    neighbors: [50, 51, 58, 60],
    locked: false,
    hidden: true
  },
  {
    q: -4,
    r: 0,
    s: 0,
    fill: "ore",
    number: 11,
    id: 60,
    neighbors: [51, 52, 59],
    locked: false,
    hidden: true
  },
];

export const Bridges = [
  {
    q: -1,
    r: 1,
    s: 0,
    fill: "bridges-top-left",
    id: 0,
  },
  {
    q: -1,
    r: 0,
    s: 0,
    fill: "bridges-top-right",
    id: 1,
  },
  {
    q: 0,
    r: -1,
    s: 0,
    fill: "bridges-right",
    id: 2,
  },
  {
    q: 1,
    r: -1,
    s: 0,
    fill: "bridges-bottom-right",
    id: 3,
  },
  {
    q: 1,
    r: 0,
    s: 0,
    fill: "bridges-bottom-left",
    id: 4,
  },
  {
    q: 0,
    r: 1,
    s: 0,
    fill: "bridges-left",
    id: 5,
  },
];

export const Ports = [
  {
    q: -1,
    r: 1,
    s: 0,
    fill: "wood-port",
    id: 0,
  },
  {
    q: -1,
    r: 0,
    s: 0,
    fill: "wood-port",
    id: 1,
  },
  {
    q: 0,
    r: -1,
    s: 0,
    fill: "wood-port",
    id: 2,
  },
  {
    q: 1,
    r: -1,
    s: 0,
    fill: "wood-port",
    id: 3,
  },
  {
    q: 1,
    r: 0,
    s: 0,
    fill: "wood-port",
    id: 4,
  },
  {
    q: 0,
    r: 1,
    s: 0,
    fill: "wood-port",
    id: 5,
  },
];